<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/faq' }">常见问题</el-breadcrumb-item>
      <el-breadcrumb-item>添加常见问题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="常见问题标题">
          <el-input v-model="pageForm.title" placeholder="请输入常见问题标题"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <upload :url="pageForm.url" :filed="'url'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="问题内容">
          <QuillBar v-model="pageForm.content" :contenttext="pageForm.content" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改设置</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      pageForm: {
        sort: 0,
        title: '',
        url: '',
        content: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      if (!this.pageForm.url) {
        this.$message.error('请上传常见问题')
        return false
      }
      const { data: res } = await this.$http.post('/pc-faq', this.pageForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.$router.push('/admin/apps/pc/faq')
      } else {
        this.$message.error(res.msg)
      }
    },
    cancel () {
      this.$router.push('/admin/apps/pc/faq')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    // 富文本变化事件
    itemChange (val) {
      this.pageForm.content = val
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
/deep/.ql-container{
  min-height: 200px;
}
</style>
